import { useState, useEffect, useRef, type ComponentProps } from "react";

import { ArrowLeft } from "@phosphor-icons/react";
import { Slot } from "@radix-ui/react-slot";
import { useNavigation, Link, type LinkProps } from "@remix-run/react";

import { cn } from "~/styles";

function Root({ className, ...props }: ComponentProps<"header">) {
  return (
    <header
      className={cn(
        "sticky top-0 z-30 bg-background bg-opacity-50 backdrop-blur-md border-border border-b border-opacity-30 flex items-center gap-2 min-h-[40px]",
        className
      )}
      {...props}
    />
  );
}

function Content({ className, ...props }: ComponentProps<"div">) {
  return (
    <div
      className={cn("w-full max-w-screen-lg flex gap-x-2 gap-y-1 justify-between items-center px-page py-1", className)}
      {...props}
    />
  );
}

function BackLink({ className, to = "..", ...props }: Omit<LinkProps, "to"> & { to?: LinkProps["to"] }) {
  return (
    <Link
      to={to}
      relative="path"
      className={cn(
        "p-1 bg-subtle bg-opacity-0 rounded-full hover:bg-opacity-20 transition-all outline hover:outline-2 outline-transparent hover:outline-subtle/20",
        className
      )}
      {...props}
    >
      <ArrowLeft />
    </Link>
  );
}

function Title({ className, ...props }: { children: React.ReactNode } & ComponentProps<"div">) {
  return <div className={cn("font-title flex items-center gap-2", className)} {...props} />;
}

function Subtitle({
  className,
  asChild = false,
  ...props
}: { asChild?: boolean; children: React.ReactNode } & ComponentProps<"div">) {
  const Comp = asChild ? Slot : "div";
  return <Comp className={cn("text-subtle text-sm flex items-center gap-2", className)} {...props} />;
}

function Logo({ to = "/" }: { to: string }) {
  const navigation = useNavigation();
  const active = navigation.state !== "idle";

  const ref = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(true);

  useEffect(() => {
    if (!ref.current) return;
    if (active) setAnimationComplete(false);

    Promise.allSettled(ref.current.getAnimations().map(({ finished }) => finished)).then(
      () => !active && setAnimationComplete(true)
    );
  }, [active]);
  return (
    <Link to={to} className="flex items-center gap-2">
      <span
        className={cn("transition-opacity", active ? "opacity-0" : "opacity-100")}
        style={{ transform: "scale(-1, 1)" }}
      >
        🏎️
      </span>
      <div className="overflow-hidden absolute right-0 left-0 pointer-events-none">
        <div
          className={cn("w-full inline-flex transition-opacity", active ? "opacity-100 animate-vroom" : "opacity-0")}
        >
          <span style={{ transform: "scale(-1, 1)" }}>🏎️</span>
        </div>
      </div>
      <span className="hidden sm:inline">Middle of the Pack</span>
      <span className="inline sm:hidden">motp</span>
    </Link>
  );
}

function GlobalLoading() {
  const navigation = useNavigation();
  const active = navigation.state !== "idle";

  const ref = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(true);

  useEffect(() => {
    if (!ref.current) return;
    if (active) setAnimationComplete(false);

    Promise.allSettled(ref.current.getAnimations().map(({ finished }) => finished)).then(
      () => !active && setAnimationComplete(true)
    );
  }, [active]);

  return (
    <div
      role="progressbar"
      aria-hidden={!active}
      aria-valuetext={active ? "Loading" : undefined}
      className="fixed inset-x-0 top-0 left-0 z-50 h-1 animate-pulse"
    >
      <div
        ref={ref}
        className={cn(
          "h-full bg-gradient-to-r from-blue-500 to-cyan-500 transition-all duration-500 ease-in-out",
          navigation.state === "idle" && animationComplete && "w-0 opacity-0 transition-none",
          navigation.state === "submitting" && "w-4/12",
          navigation.state === "loading" && "w-10/12",
          navigation.state === "idle" && !animationComplete && "w-full"
        )}
      />
    </div>
  );
}

export { Root, Content, BackLink, Title, Subtitle, Logo };
